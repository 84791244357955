/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountChangeEmailModel,
  AccountGetAccountUsersByIdsUserIdsParameterInner,
  AccountOrderRequestQuery,
  AccountUserAddModel,
  AccountUserModifyModel,
  AccountUsersResponseModel,
  ActivityLogFilter,
  BadRequest,
  Conflict,
  CustomerResponseModel,
  Forbidden,
  NotFound,
  OAuthClientCredentialsAddModel,
  OAuthClientCredentialsGenerateResponse,
  OAuthClientCredentialsResetModel,
  OAuthClientCredentialsResponse,
  PaginatedActivityLogResponse,
  Unauthorized,
} from '../models';

export interface AccountAddAccountUserRequest {
    accountUserAddModel: AccountUserAddModel;
}

export interface AccountChangeAccountEmailRequest {
    accountChangeEmailModel: AccountChangeEmailModel;
}

export interface AccountDeleteClientCredentialsRequest {
    id: string;
}

export interface AccountGenerateClientCredentialsRequest {
    oAuthClientCredentialsAddModel: OAuthClientCredentialsAddModel;
}

export interface AccountGetAccountUsersRequest {
    page?: number;
    limit?: number;
    filterValue?: string;
    order?: AccountOrderRequestQuery;
}

export interface AccountGetAccountUsersByIdsRequest {
    userIds: Array<AccountGetAccountUsersByIdsUserIdsParameterInner>;
    page?: number;
    limit?: number;
    order?: AccountOrderRequestQuery;
}

export interface AccountGetAuditLogsForAccountRequest {
    filter: ActivityLogFilter;
    page?: number;
    limit?: number;
}

export interface AccountModifyAccountUserRequest {
    id: string;
    accountUserModifyModel: AccountUserModifyModel;
}

export interface AccountRemoveAccountUserRequest {
    id: string;
}

export interface AccountRemoveUserMultiFactorAuthRequest {
    id: string;
}

export interface AccountResetClientCredentialsRequest {
    oAuthClientCredentialsResetModel: OAuthClientCredentialsResetModel;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Add user to an account
     */
    async accountAddAccountUserRaw(requestParameters: AccountAddAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountUserAddModel === null || requestParameters.accountUserAddModel === undefined) {
            throw new runtime.RequiredError('accountUserAddModel','Required parameter requestParameters.accountUserAddModel was null or undefined when calling accountAddAccountUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountUserAddModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add user to an account
     */
    async accountAddAccountUser(requestParameters: AccountAddAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountAddAccountUserRaw(requestParameters, initOverrides);
    }

    /**
     * Perform email change process via IDP
     */
    async accountChangeAccountEmailRaw(requestParameters: AccountChangeAccountEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.accountChangeEmailModel === null || requestParameters.accountChangeEmailModel === undefined) {
            throw new runtime.RequiredError('accountChangeEmailModel','Required parameter requestParameters.accountChangeEmailModel was null or undefined when calling accountChangeAccountEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountChangeEmailModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform email change process via IDP
     */
    async accountChangeAccountEmail(requestParameters: AccountChangeAccountEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountChangeAccountEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Perform password change process via IDP
     */
    async accountChangeAccountPasswordRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/changePassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform password change process via IDP
     */
    async accountChangeAccountPassword(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountChangeAccountPasswordRaw(initOverrides);
    }

    /**
     * Delete client credentials for the customer
     */
    async accountDeleteClientCredentialsRaw(requestParameters: AccountDeleteClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountDeleteClientCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/client-credentials/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete client credentials for the customer
     */
    async accountDeleteClientCredentials(requestParameters: AccountDeleteClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountDeleteClientCredentialsRaw(requestParameters, initOverrides);
    }

    /**
     * Generate client credentials for the customer
     */
    async accountGenerateClientCredentialsRaw(requestParameters: AccountGenerateClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OAuthClientCredentialsGenerateResponse>>> {
        if (requestParameters.oAuthClientCredentialsAddModel === null || requestParameters.oAuthClientCredentialsAddModel === undefined) {
            throw new runtime.RequiredError('oAuthClientCredentialsAddModel','Required parameter requestParameters.oAuthClientCredentialsAddModel was null or undefined when calling accountGenerateClientCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/client-credentials`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.oAuthClientCredentialsAddModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Generate client credentials for the customer
     */
    async accountGenerateClientCredentials(requestParameters: AccountGenerateClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OAuthClientCredentialsGenerateResponse>> {
        const response = await this.accountGenerateClientCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Information
     */
    async accountGetAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Account Information
     */
    async accountGetAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerResponseModel> {
        const response = await this.accountGetAccountRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Account Users
     */
    async accountGetAccountUsersRaw(requestParameters: AccountGetAccountUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountUsersResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.filterValue !== undefined) {
            queryParameters['filterValue'] = requestParameters.filterValue;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Account Users
     */
    async accountGetAccountUsers(requestParameters: AccountGetAccountUsersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountUsersResponseModel> {
        const response = await this.accountGetAccountUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Account Users by provided Ids
     */
    async accountGetAccountUsersByIdsRaw(requestParameters: AccountGetAccountUsersByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountUsersResponseModel>> {
        if (requestParameters.userIds === null || requestParameters.userIds === undefined) {
            throw new runtime.RequiredError('userIds','Required parameter requestParameters.userIds was null or undefined when calling accountGetAccountUsersByIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.userIds) {
            queryParameters['userIds'] = requestParameters.userIds;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/usersByIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Account Users by provided Ids
     */
    async accountGetAccountUsersByIds(requestParameters: AccountGetAccountUsersByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountUsersResponseModel> {
        const response = await this.accountGetAccountUsersByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * { [source]: { [application] :{ {actionTypes: string[], objectSource?: string[]}}
     * Get list of audit logs filters
     */
    async accountGetActivitiesFiltersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/activities/filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * { [source]: { [application] :{ {actionTypes: string[], objectSource?: string[]}}
     * Get list of audit logs filters
     */
    async accountGetActivitiesFilters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.accountGetActivitiesFiltersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get list of audit logs
     */
    async accountGetAuditLogsForAccountRaw(requestParameters: AccountGetAuditLogsForAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedActivityLogResponse>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling accountGetAuditLogsForAccount.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/activities`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of audit logs
     */
    async accountGetAuditLogsForAccount(requestParameters: AccountGetAuditLogsForAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedActivityLogResponse> {
        const response = await this.accountGetAuditLogsForAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Generate client credentials for the customer
     */
    async accountGetClientCredentialsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OAuthClientCredentialsResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/client-credentials`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Generate client credentials for the customer
     */
    async accountGetClientCredentials(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OAuthClientCredentialsResponse>> {
        const response = await this.accountGetClientCredentialsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a user
     */
    async accountModifyAccountUserRaw(requestParameters: AccountModifyAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountModifyAccountUser.');
        }

        if (requestParameters.accountUserModifyModel === null || requestParameters.accountUserModifyModel === undefined) {
            throw new runtime.RequiredError('accountUserModifyModel','Required parameter requestParameters.accountUserModifyModel was null or undefined when calling accountModifyAccountUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountUserModifyModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a user
     */
    async accountModifyAccountUser(requestParameters: AccountModifyAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountModifyAccountUserRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a user
     */
    async accountRemoveAccountUserRaw(requestParameters: AccountRemoveAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountRemoveAccountUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a user
     */
    async accountRemoveAccountUser(requestParameters: AccountRemoveAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountRemoveAccountUserRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a user multifactor authentication
     */
    async accountRemoveUserMultiFactorAuthRaw(requestParameters: AccountRemoveUserMultiFactorAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling accountRemoveUserMultiFactorAuth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/users/{id}/multifactor`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a user multifactor authentication
     */
    async accountRemoveUserMultiFactorAuth(requestParameters: AccountRemoveUserMultiFactorAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountRemoveUserMultiFactorAuthRaw(requestParameters, initOverrides);
    }

    /**
     * Reset client credentials of the customer
     */
    async accountResetClientCredentialsRaw(requestParameters: AccountResetClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OAuthClientCredentialsGenerateResponse>>> {
        if (requestParameters.oAuthClientCredentialsResetModel === null || requestParameters.oAuthClientCredentialsResetModel === undefined) {
            throw new runtime.RequiredError('oAuthClientCredentialsResetModel','Required parameter requestParameters.oAuthClientCredentialsResetModel was null or undefined when calling accountResetClientCredentials.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/client-credentials/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.oAuthClientCredentialsResetModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Reset client credentials of the customer
     */
    async accountResetClientCredentials(requestParameters: AccountResetClientCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OAuthClientCredentialsGenerateResponse>> {
        const response = await this.accountResetClientCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Perform email validation process via IDP
     */
    async accountValidateAccountRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/account/validate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Perform email validation process via IDP
     */
    async accountValidateAccount(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.accountValidateAccountRaw(initOverrides);
    }

}
