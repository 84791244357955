/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Forbidden,
  Unauthorized,
  WallboxInstallationRequestModel,
  WallboxModelResponseModel,
} from '../models';

export interface CrmInstallationsWallBoxAddWallboxSmartEnergyDeviceRequest {
    id: string;
    wallboxInstallationRequestModel: WallboxInstallationRequestModel;
}

export interface CrmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRequest {
    id: string;
    deviceId: string;
}

export interface CrmInstallationsWallBoxGetSmartEnergyDeviceRequest {
    id: string;
    deviceId: string;
}

export interface CrmInstallationsWallBoxGetWallboxDevicesRequest {
    id: string;
    useCache?: string;
}

export interface CrmInstallationsWallBoxUpdateSmartEnergyDeviceRequest {
    id: string;
    installationId: string;
    wallboxInstallationRequestModel: WallboxInstallationRequestModel;
}

/**
 * 
 */
export class CRMInstallationsWallBoxApi extends runtime.BaseAPI {

    /**
     * Add a Wall Box configuration
     */
    async crmInstallationsWallBoxAddWallboxSmartEnergyDeviceRaw(requestParameters: CrmInstallationsWallBoxAddWallboxSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxModelResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsWallBoxAddWallboxSmartEnergyDevice.');
        }

        if (requestParameters.wallboxInstallationRequestModel === null || requestParameters.wallboxInstallationRequestModel === undefined) {
            throw new runtime.RequiredError('wallboxInstallationRequestModel','Required parameter requestParameters.wallboxInstallationRequestModel was null or undefined when calling crmInstallationsWallBoxAddWallboxSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/wallbox`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallboxInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a Wall Box configuration
     */
    async crmInstallationsWallBoxAddWallboxSmartEnergyDevice(requestParameters: CrmInstallationsWallBoxAddWallboxSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxModelResponseModel> {
        const response = await this.crmInstallationsWallBoxAddWallboxSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove a Wall Box configuration
     */
    async crmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRaw(requestParameters: CrmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsWallBoxDeleteWallboxSmartEnergyDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling crmInstallationsWallBoxDeleteWallboxSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/wallbox/{deviceId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a Wall Box configuration
     */
    async crmInstallationsWallBoxDeleteWallboxSmartEnergyDevice(requestParameters: CrmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmInstallationsWallBoxDeleteWallboxSmartEnergyDeviceRaw(requestParameters, initOverrides);
    }

    /**
     * Find a Wall Box configuration
     */
    async crmInstallationsWallBoxGetSmartEnergyDeviceRaw(requestParameters: CrmInstallationsWallBoxGetSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxModelResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsWallBoxGetSmartEnergyDevice.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling crmInstallationsWallBoxGetSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/wallbox/{deviceId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"deviceId"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a Wall Box configuration
     */
    async crmInstallationsWallBoxGetSmartEnergyDevice(requestParameters: CrmInstallationsWallBoxGetSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxModelResponseModel> {
        const response = await this.crmInstallationsWallBoxGetSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all Wall Box installations
     */
    async crmInstallationsWallBoxGetWallboxDevicesRaw(requestParameters: CrmInstallationsWallBoxGetWallboxDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WallboxModelResponseModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsWallBoxGetWallboxDevices.');
        }

        const queryParameters: any = {};

        if (requestParameters.useCache !== undefined) {
            queryParameters['useCache'] = requestParameters.useCache;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/wallbox`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get all Wall Box installations
     */
    async crmInstallationsWallBoxGetWallboxDevices(requestParameters: CrmInstallationsWallBoxGetWallboxDevicesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WallboxModelResponseModel>> {
        const response = await this.crmInstallationsWallBoxGetWallboxDevicesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Wall Box configuration
     */
    async crmInstallationsWallBoxUpdateSmartEnergyDeviceRaw(requestParameters: CrmInstallationsWallBoxUpdateSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WallboxModelResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmInstallationsWallBoxUpdateSmartEnergyDevice.');
        }

        if (requestParameters.installationId === null || requestParameters.installationId === undefined) {
            throw new runtime.RequiredError('installationId','Required parameter requestParameters.installationId was null or undefined when calling crmInstallationsWallBoxUpdateSmartEnergyDevice.');
        }

        if (requestParameters.wallboxInstallationRequestModel === null || requestParameters.wallboxInstallationRequestModel === undefined) {
            throw new runtime.RequiredError('wallboxInstallationRequestModel','Required parameter requestParameters.wallboxInstallationRequestModel was null or undefined when calling crmInstallationsWallBoxUpdateSmartEnergyDevice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/installations/wallbox/{installationId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"installationId"}}`, encodeURIComponent(String(requestParameters.installationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.wallboxInstallationRequestModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a Wall Box configuration
     */
    async crmInstallationsWallBoxUpdateSmartEnergyDevice(requestParameters: CrmInstallationsWallBoxUpdateSmartEnergyDeviceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WallboxModelResponseModel> {
        const response = await this.crmInstallationsWallBoxUpdateSmartEnergyDeviceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
