/* tslint:disable */
/* eslint-disable */
/**
 * E.ON Home Admin Portal - API
 * # Introduction    This API provides access to admin and customer management.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: sebastian.eggers@eon.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountChangeAddressModel,
  AccountChangeEmailModel,
  BadRequest,
  Conflict,
  CreatedCustomerResponseModel,
  CustomerAddFeatureGroupModel,
  CustomerAddModel,
  CustomerBasicResponseModel,
  CustomerChangeSubscriptionsModel,
  CustomerChangeTenantModel,
  CustomerCompareModel,
  CustomerDeactivateModel,
  CustomerFeatureGroupResponseModel,
  CustomerGatewayHealthResponseModel,
  CustomerGlobalSites,
  CustomerIdpProviderResponseModel,
  CustomerRemoveFeatureGroupModel,
  CustomerRemoveModel,
  CustomerResponseModel,
  CustomerStats,
  CustomerStatsChildren,
  CustomerStatsFilter,
  CustomerUpdateModel,
  CustomerUserMainResponseModel,
  CustomerUserResponseModel,
  CustomerUserUpdateModel,
  CustomersFilter,
  CustomersOrder,
  CustomersResponseModel,
  DashboardAccessTokensModel,
  ErrorResponseModel,
  Forbidden,
  HealthStatusAlarmsModel,
  HealthStatusModel,
  HealthStatusRequest,
  InstallationStatsModel,
  NotFound,
  PaginatedActivityLogResponse,
  SentInvitationResponseModel,
  TechnicalCustomerAddModel,
  Unauthorized,
  UserReactivationModel,
  VendorModel,
} from '../models';

export interface CrmCustomersAddAccountUserRequest {
    customerAddModel: CustomerAddModel;
}

export interface CrmCustomersAddCustomerToSubtenantRequest {
    id: string;
}

export interface CrmCustomersAddTechnicalCustomerRequest {
    technicalCustomerAddModel: TechnicalCustomerAddModel;
}

export interface CrmCustomersAssignFeatureGroupToCustomerRequest {
    id: string;
    customerAddFeatureGroupModel: CustomerAddFeatureGroupModel;
}

export interface CrmCustomersCompareCustomerDataRequest {
    customerId: string;
    configId: string;
    date?: string;
}

export interface CrmCustomersCustomerChangeSiteAddressRequest {
    id: string;
    siteId: string;
    accountChangeAddressModel: AccountChangeAddressModel;
}

export interface CrmCustomersCustomerGetIdpProviderRequest {
    id: string;
}

export interface CrmCustomersCustomerGetSitesRequest {
    id: string;
}

export interface CrmCustomersCustomerUserChangeEmailRequest {
    id: string;
    accountChangeEmailModel: AccountChangeEmailModel;
}

export interface CrmCustomersCustomerUserResetPasswordRequest {
    id: string;
}

export interface CrmCustomersCustomerUserValidateRequest {
    id: string;
}

export interface CrmCustomersDeactivateCustomerRequest {
    id: string;
    customerDeactivateModel: CustomerDeactivateModel;
}

export interface CrmCustomersDeleteCustomerRequest {
    id: string;
    customerRemoveModel: CustomerRemoveModel;
}

export interface CrmCustomersGetAuthTokensRequest {
    id: string;
}

export interface CrmCustomersGetCsvUploadLogsRequest {
    page?: number;
    limit?: number;
    date?: number;
}

export interface CrmCustomersGetCsvUploadLogsForCustomerRequest {
    id: string;
    page?: number;
    limit?: number;
    date?: number;
}

export interface CrmCustomersGetCustomerRequest {
    id: string;
}

export interface CrmCustomersGetCustomerAllocationsRequest {
    customerId: string;
}

export interface CrmCustomersGetCustomerCustomersRequest {
    id: string;
    page?: number;
    limit?: number;
    searchValue?: string;
    filter?: CustomersFilter;
    order?: CustomersOrder;
    csvExport?: boolean;
}

export interface CrmCustomersGetCustomerGatewayHealthRequest {
    id: string;
}

export interface CrmCustomersGetCustomerGatewayHealthHistoryRequest {
    id: string;
}

export interface CrmCustomersGetCustomerInstallationsStatsRequest {
    id: string;
}

export interface CrmCustomersGetCustomerStatsRequest {
    id: string;
    filters?: CustomerStatsFilter;
}

export interface CrmCustomersGetCustomerStatsChildrenRequest {
    id: string;
    filters?: CustomerStatsFilter;
}

export interface CrmCustomersGetCustomerUserRequest {
    id: string;
    userId: string;
}

export interface CrmCustomersGetCustomerUserBasicRequest {
    id: string;
    userId: string;
}

export interface CrmCustomersGetCustomerUserIncludingTenantNameRequest {
    id: string;
    tenantUserUid: string;
}

export interface CrmCustomersGetCustomerUsersRequest {
    id: string;
}

export interface CrmCustomersGetCustomersRequest {
    page?: number;
    limit?: number;
    searchValue?: string;
    filter?: CustomersFilter;
    order?: CustomersOrder;
    csvExport?: boolean;
}

export interface CrmCustomersGetInstallationsGatewayAlarmsRequest {
    alarmLevel?: string;
    active?: string;
}

export interface CrmCustomersGetInstallationsHealthRequest {
    healthStatusRequest: HealthStatusRequest;
}

export interface CrmCustomersGetInstallationsHealthAlarmsRequest {
    alarmLevel?: string;
    active?: string;
}

export interface CrmCustomersGetInstallationsHealthAlarmsByCustomerIdRequest {
    customerId: string;
}

export interface CrmCustomersGetSendAccountUserInvitationsRequest {
    id: string;
}

export interface CrmCustomersGetStatsRequest {
    filters?: CustomerStatsFilter;
}

export interface CrmCustomersGetStatsCustomersRequest {
    filters?: CustomerStatsFilter;
}

export interface CrmCustomersReactivateCustomerRequest {
    id: string;
}

export interface CrmCustomersRemoveAssignedToCustomerFeatureGroupRequest {
    id: string;
    customerRemoveFeatureGroupModel: CustomerRemoveFeatureGroupModel;
}

export interface CrmCustomersSendAccountUserInvitationRequest {
    id: string;
}

export interface CrmCustomersUpdateAccountSubscriptionRequest {
    id: string;
    customerChangeSubscriptionsModel: CustomerChangeSubscriptionsModel;
}

export interface CrmCustomersUpdateAccountUserRequest {
    id: string;
    customerChangeTenantModel: CustomerChangeTenantModel;
}

export interface CrmCustomersUpdateCustomerRequest {
    id: string;
    customerUpdateModel: CustomerUpdateModel;
}

export interface CrmCustomersUpdateUserRequest {
    id: string;
    userId: string;
    customerUserUpdateModel: CustomerUserUpdateModel;
}

export interface CrmCustomersUploadCsvCustomersRequest {
    csvData: Blob;
}

export interface CrmCustomersUploadCsvForCustomerRequest {
    id: string;
    csvData: Blob;
}

/**
 * 
 */
export class CRMCustomersApi extends runtime.BaseAPI {

    /**
     * Add a customer
     */
    async crmCustomersAddAccountUserRaw(requestParameters: CrmCustomersAddAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedCustomerResponseModel>> {
        if (requestParameters.customerAddModel === null || requestParameters.customerAddModel === undefined) {
            throw new runtime.RequiredError('customerAddModel','Required parameter requestParameters.customerAddModel was null or undefined when calling crmCustomersAddAccountUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerAddModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a customer
     */
    async crmCustomersAddAccountUser(requestParameters: CrmCustomersAddAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedCustomerResponseModel> {
        const response = await this.crmCustomersAddAccountUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Add a customer to a sub-tenant
     */
    async crmCustomersAddCustomerToSubtenantRaw(requestParameters: CrmCustomersAddCustomerToSubtenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersAddCustomerToSubtenant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/customers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a customer to a sub-tenant
     */
    async crmCustomersAddCustomerToSubtenant(requestParameters: CrmCustomersAddCustomerToSubtenantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersAddCustomerToSubtenantRaw(requestParameters, initOverrides);
    }

    /**
     * Add a technical customer
     */
    async crmCustomersAddTechnicalCustomerRaw(requestParameters: CrmCustomersAddTechnicalCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatedCustomerResponseModel>> {
        if (requestParameters.technicalCustomerAddModel === null || requestParameters.technicalCustomerAddModel === undefined) {
            throw new runtime.RequiredError('technicalCustomerAddModel','Required parameter requestParameters.technicalCustomerAddModel was null or undefined when calling crmCustomersAddTechnicalCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/technical`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.technicalCustomerAddModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Add a technical customer
     */
    async crmCustomersAddTechnicalCustomer(requestParameters: CrmCustomersAddTechnicalCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatedCustomerResponseModel> {
        const response = await this.crmCustomersAddTechnicalCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Assign a feature group to existing customer. Can update the ext_reference_1 if present in the request
     * Assign a feature group to existing customer
     */
    async crmCustomersAssignFeatureGroupToCustomerRaw(requestParameters: CrmCustomersAssignFeatureGroupToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerFeatureGroupResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersAssignFeatureGroupToCustomer.');
        }

        if (requestParameters.customerAddFeatureGroupModel === null || requestParameters.customerAddFeatureGroupModel === undefined) {
            throw new runtime.RequiredError('customerAddFeatureGroupModel','Required parameter requestParameters.customerAddFeatureGroupModel was null or undefined when calling crmCustomersAssignFeatureGroupToCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/feature-groups-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerAddFeatureGroupModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Assign a feature group to existing customer. Can update the ext_reference_1 if present in the request
     * Assign a feature group to existing customer
     */
    async crmCustomersAssignFeatureGroupToCustomer(requestParameters: CrmCustomersAssignFeatureGroupToCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerFeatureGroupResponseModel> {
        const response = await this.crmCustomersAssignFeatureGroupToCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get combined list of data to compare for specified customer
     */
    async crmCustomersCompareCustomerDataRaw(requestParameters: CrmCustomersCompareCustomerDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerCompareModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling crmCustomersCompareCustomerData.');
        }

        if (requestParameters.configId === null || requestParameters.configId === undefined) {
            throw new runtime.RequiredError('configId','Required parameter requestParameters.configId was null or undefined when calling crmCustomersCompareCustomerData.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{customerId}/config/{configId}/compare`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))).replace(`{${"configId"}}`, encodeURIComponent(String(requestParameters.configId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get combined list of data to compare for specified customer
     */
    async crmCustomersCompareCustomerData(requestParameters: CrmCustomersCompareCustomerDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerCompareModel> {
        const response = await this.crmCustomersCompareCustomerDataRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change site zip code/address
     */
    async crmCustomersCustomerChangeSiteAddressRaw(requestParameters: CrmCustomersCustomerChangeSiteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerChangeSiteAddress.');
        }

        if (requestParameters.siteId === null || requestParameters.siteId === undefined) {
            throw new runtime.RequiredError('siteId','Required parameter requestParameters.siteId was null or undefined when calling crmCustomersCustomerChangeSiteAddress.');
        }

        if (requestParameters.accountChangeAddressModel === null || requestParameters.accountChangeAddressModel === undefined) {
            throw new runtime.RequiredError('accountChangeAddressModel','Required parameter requestParameters.accountChangeAddressModel was null or undefined when calling crmCustomersCustomerChangeSiteAddress.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/sites/{siteId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"siteId"}}`, encodeURIComponent(String(requestParameters.siteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountChangeAddressModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change site zip code/address
     */
    async crmCustomersCustomerChangeSiteAddress(requestParameters: CrmCustomersCustomerChangeSiteAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersCustomerChangeSiteAddressRaw(requestParameters, initOverrides);
    }

    /**
     * Get customers IDP Provider - includes dashboard URL needed for impersonation
     */
    async crmCustomersCustomerGetIdpProviderRaw(requestParameters: CrmCustomersCustomerGetIdpProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerIdpProviderResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerGetIdpProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/idpprovider`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customers IDP Provider - includes dashboard URL needed for impersonation
     */
    async crmCustomersCustomerGetIdpProvider(requestParameters: CrmCustomersCustomerGetIdpProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerIdpProviderResponseModel> {
        const response = await this.crmCustomersCustomerGetIdpProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer site list
     */
    async crmCustomersCustomerGetSitesRaw(requestParameters: CrmCustomersCustomerGetSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerGlobalSites>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerGetSites.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/sites`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer site list
     */
    async crmCustomersCustomerGetSites(requestParameters: CrmCustomersCustomerGetSitesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerGlobalSites>> {
        const response = await this.crmCustomersCustomerGetSitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change user email on IDP and our side
     */
    async crmCustomersCustomerUserChangeEmailRaw(requestParameters: CrmCustomersCustomerUserChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerUserChangeEmail.');
        }

        if (requestParameters.accountChangeEmailModel === null || requestParameters.accountChangeEmailModel === undefined) {
            throw new runtime.RequiredError('accountChangeEmailModel','Required parameter requestParameters.accountChangeEmailModel was null or undefined when calling crmCustomersCustomerUserChangeEmail.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/users/{id}/email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.accountChangeEmailModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change user email on IDP and our side
     */
    async crmCustomersCustomerUserChangeEmail(requestParameters: CrmCustomersCustomerUserChangeEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersCustomerUserChangeEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Send IDP reset password
     */
    async crmCustomersCustomerUserResetPasswordRaw(requestParameters: CrmCustomersCustomerUserResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerUserResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/users/{id}/resetpassword`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send IDP reset password
     */
    async crmCustomersCustomerUserResetPassword(requestParameters: CrmCustomersCustomerUserResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersCustomerUserResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Validate user on IDP side
     */
    async crmCustomersCustomerUserValidateRaw(requestParameters: CrmCustomersCustomerUserValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersCustomerUserValidate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/users/{id}/validate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate user on IDP side
     */
    async crmCustomersCustomerUserValidate(requestParameters: CrmCustomersCustomerUserValidateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersCustomerUserValidateRaw(requestParameters, initOverrides);
    }

    /**
     * Performs deactivation action on a customer, which marks it for deletion after set interval.
     */
    async crmCustomersDeactivateCustomerRaw(requestParameters: CrmCustomersDeactivateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersDeactivateCustomer.');
        }

        if (requestParameters.customerDeactivateModel === null || requestParameters.customerDeactivateModel === undefined) {
            throw new runtime.RequiredError('customerDeactivateModel','Required parameter requestParameters.customerDeactivateModel was null or undefined when calling crmCustomersDeactivateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/deactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerDeactivateModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Performs deactivation action on a customer, which marks it for deletion after set interval.
     */
    async crmCustomersDeactivateCustomer(requestParameters: CrmCustomersDeactivateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersDeactivateCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * Remove a customer
     */
    async crmCustomersDeleteCustomerRaw(requestParameters: CrmCustomersDeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersDeleteCustomer.');
        }

        if (requestParameters.customerRemoveModel === null || requestParameters.customerRemoveModel === undefined) {
            throw new runtime.RequiredError('customerRemoveModel','Required parameter requestParameters.customerRemoveModel was null or undefined when calling crmCustomersDeleteCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerRemoveModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a customer
     */
    async crmCustomersDeleteCustomer(requestParameters: CrmCustomersDeleteCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersDeleteCustomerRaw(requestParameters, initOverrides);
    }

    /**
     * A temporary token to access data on users behalf such as SSE API.
     * Temporary Token
     */
    async crmCustomersGetAuthTokensRaw(requestParameters: CrmCustomersGetAuthTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardAccessTokensModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetAuthTokens.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/token`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * A temporary token to access data on users behalf such as SSE API.
     * Temporary Token
     */
    async crmCustomersGetAuthTokens(requestParameters: CrmCustomersGetAuthTokensRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardAccessTokensModel> {
        const response = await this.crmCustomersGetAuthTokensRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the logs of customer csv upload. Date param is optional
     */
    async crmCustomersGetCsvUploadLogsRaw(requestParameters: CrmCustomersGetCsvUploadLogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedActivityLogResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/upload/logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the logs of customer csv upload. Date param is optional
     */
    async crmCustomersGetCsvUploadLogs(requestParameters: CrmCustomersGetCsvUploadLogsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedActivityLogResponse> {
        const response = await this.crmCustomersGetCsvUploadLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the logs of customer csv upload. Date param is optional
     */
    async crmCustomersGetCsvUploadLogsForCustomerRaw(requestParameters: CrmCustomersGetCsvUploadLogsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedActivityLogResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCsvUploadLogsForCustomer.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/upload/logs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Retrieves the logs of customer csv upload. Date param is optional
     */
    async crmCustomersGetCsvUploadLogsForCustomer(requestParameters: CrmCustomersGetCsvUploadLogsForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedActivityLogResponse> {
        const response = await this.crmCustomersGetCsvUploadLogsForCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find a customer
     */
    async crmCustomersGetCustomerRaw(requestParameters: CrmCustomersGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Find a customer
     */
    async crmCustomersGetCustomer(requestParameters: CrmCustomersGetCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerResponseModel> {
        const response = await this.crmCustomersGetCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer\'s vendor list by the customer\'s allocation.
     */
    async crmCustomersGetCustomerAllocationsRaw(requestParameters: CrmCustomersGetCustomerAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<VendorModel>>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling crmCustomersGetCustomerAllocations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{customerId}/allocations`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer\'s vendor list by the customer\'s allocation.
     */
    async crmCustomersGetCustomerAllocations(requestParameters: CrmCustomersGetCustomerAllocationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<VendorModel>> {
        const response = await this.crmCustomersGetCustomerAllocationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customers of sub-tenant
     */
    async crmCustomersGetCustomerCustomersRaw(requestParameters: CrmCustomersGetCustomerCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomersResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerCustomers.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.searchValue !== undefined) {
            queryParameters['searchValue'] = requestParameters.searchValue;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.csvExport !== undefined) {
            queryParameters['csvExport'] = requestParameters.csvExport;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/customers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customers of sub-tenant
     */
    async crmCustomersGetCustomerCustomers(requestParameters: CrmCustomersGetCustomerCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomersResponseModel> {
        const response = await this.crmCustomersGetCustomerCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get gateway statuses for a customer
     */
    async crmCustomersGetCustomerGatewayHealthRaw(requestParameters: CrmCustomersGetCustomerGatewayHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerGatewayHealthResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerGatewayHealth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/gateways/status`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway statuses for a customer
     */
    async crmCustomersGetCustomerGatewayHealth(requestParameters: CrmCustomersGetCustomerGatewayHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerGatewayHealthResponseModel> {
        const response = await this.crmCustomersGetCustomerGatewayHealthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get gateway status history for a customer
     */
    async crmCustomersGetCustomerGatewayHealthHistoryRaw(requestParameters: CrmCustomersGetCustomerGatewayHealthHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerGatewayHealthResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerGatewayHealthHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/gateways/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get gateway status history for a customer
     */
    async crmCustomersGetCustomerGatewayHealthHistory(requestParameters: CrmCustomersGetCustomerGatewayHealthHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerGatewayHealthResponseModel> {
        const response = await this.crmCustomersGetCustomerGatewayHealthHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer installation KPI`s based on customerUUID
     */
    async crmCustomersGetCustomerInstallationsStatsRaw(requestParameters: CrmCustomersGetCustomerInstallationsStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationStatsModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerInstallationsStats.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/stats/installations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer installation KPI`s based on customerUUID
     */
    async crmCustomersGetCustomerInstallationsStats(requestParameters: CrmCustomersGetCustomerInstallationsStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationStatsModel> {
        const response = await this.crmCustomersGetCustomerInstallationsStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer KPI`s based on customerUUID
     */
    async crmCustomersGetCustomerStatsRaw(requestParameters: CrmCustomersGetCustomerStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerStats.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer KPI`s based on customerUUID
     */
    async crmCustomersGetCustomerStats(requestParameters: CrmCustomersGetCustomerStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStats> {
        const response = await this.crmCustomersGetCustomerStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer stats data with the list of children records
     */
    async crmCustomersGetCustomerStatsChildrenRaw(requestParameters: CrmCustomersGetCustomerStatsChildrenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStatsChildren>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerStatsChildren.');
        }

        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/stats/children`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer stats data with the list of children records
     */
    async crmCustomersGetCustomerStatsChildren(requestParameters: CrmCustomersGetCustomerStatsChildrenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStatsChildren> {
        const response = await this.crmCustomersGetCustomerStatsChildrenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer user
     */
    async crmCustomersGetCustomerUserRaw(requestParameters: CrmCustomersGetCustomerUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerUserMainResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling crmCustomersGetCustomerUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/users/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer user
     */
    async crmCustomersGetCustomerUser(requestParameters: CrmCustomersGetCustomerUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerUserMainResponseModel> {
        const response = await this.crmCustomersGetCustomerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Basic information of a user
     */
    async crmCustomersGetCustomerUserBasicRaw(requestParameters: CrmCustomersGetCustomerUserBasicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerBasicResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerUserBasic.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling crmCustomersGetCustomerUserBasic.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/users/{userId}/basic`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get Basic information of a user
     */
    async crmCustomersGetCustomerUserBasic(requestParameters: CrmCustomersGetCustomerUserBasicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerBasicResponseModel> {
        const response = await this.crmCustomersGetCustomerUserBasicRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get user by unique customer id and by tenant unique user id, response is enriched with tenant name
     */
    async crmCustomersGetCustomerUserIncludingTenantNameRaw(requestParameters: CrmCustomersGetCustomerUserIncludingTenantNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerUserIncludingTenantName.');
        }

        if (requestParameters.tenantUserUid === null || requestParameters.tenantUserUid === undefined) {
            throw new runtime.RequiredError('tenantUserUid','Required parameter requestParameters.tenantUserUid was null or undefined when calling crmCustomersGetCustomerUserIncludingTenantName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/tenant/{tenantUserUid}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"tenantUserUid"}}`, encodeURIComponent(String(requestParameters.tenantUserUid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Get user by unique customer id and by tenant unique user id, response is enriched with tenant name
     */
    async crmCustomersGetCustomerUserIncludingTenantName(requestParameters: CrmCustomersGetCustomerUserIncludingTenantNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.crmCustomersGetCustomerUserIncludingTenantNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users of a customer
     */
    async crmCustomersGetCustomerUsersRaw(requestParameters: CrmCustomersGetCustomerUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerUserResponseModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetCustomerUsers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/users`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get users of a customer
     */
    async crmCustomersGetCustomerUsers(requestParameters: CrmCustomersGetCustomerUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerUserResponseModel>> {
        const response = await this.crmCustomersGetCustomerUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get list of Customers
     */
    async crmCustomersGetCustomersRaw(requestParameters: CrmCustomersGetCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomersResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.searchValue !== undefined) {
            queryParameters['searchValue'] = requestParameters.searchValue;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        if (requestParameters.csvExport !== undefined) {
            queryParameters['csvExport'] = requestParameters.csvExport;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get list of Customers
     */
    async crmCustomersGetCustomers(requestParameters: CrmCustomersGetCustomersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomersResponseModel> {
        const response = await this.crmCustomersGetCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the installation gateway alarms of all customers. If no alarmLevel provided, returns all non-OK gateways otherwise those with provided value.
     */
    async crmCustomersGetInstallationsGatewayAlarmsRaw(requestParameters: CrmCustomersGetInstallationsGatewayAlarmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CustomerGatewayHealthResponseModel>>> {
        const queryParameters: any = {};

        if (requestParameters.alarmLevel !== undefined) {
            queryParameters['alarmLevel'] = requestParameters.alarmLevel;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/installations/gateways/alarms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the installation gateway alarms of all customers. If no alarmLevel provided, returns all non-OK gateways otherwise those with provided value.
     */
    async crmCustomersGetInstallationsGatewayAlarms(requestParameters: CrmCustomersGetInstallationsGatewayAlarmsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CustomerGatewayHealthResponseModel>> {
        const response = await this.crmCustomersGetInstallationsGatewayAlarmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the installation health of all provided customer ids
     */
    async crmCustomersGetInstallationsHealthRaw(requestParameters: CrmCustomersGetInstallationsHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthStatusModel>> {
        if (requestParameters.healthStatusRequest === null || requestParameters.healthStatusRequest === undefined) {
            throw new runtime.RequiredError('healthStatusRequest','Required parameter requestParameters.healthStatusRequest was null or undefined when calling crmCustomersGetInstallationsHealth.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/installations/health`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.healthStatusRequest,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the installation health of all provided customer ids
     */
    async crmCustomersGetInstallationsHealth(requestParameters: CrmCustomersGetInstallationsHealthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthStatusModel> {
        const response = await this.crmCustomersGetInstallationsHealthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the installation health alarms of all customers
     */
    async crmCustomersGetInstallationsHealthAlarmsRaw(requestParameters: CrmCustomersGetInstallationsHealthAlarmsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthStatusAlarmsModel>> {
        const queryParameters: any = {};

        if (requestParameters.alarmLevel !== undefined) {
            queryParameters['alarmLevel'] = requestParameters.alarmLevel;
        }

        if (requestParameters.active !== undefined) {
            queryParameters['active'] = requestParameters.active;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/installations/health/alarms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the installation health alarms of all customers
     */
    async crmCustomersGetInstallationsHealthAlarms(requestParameters: CrmCustomersGetInstallationsHealthAlarmsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthStatusAlarmsModel> {
        const response = await this.crmCustomersGetInstallationsHealthAlarmsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the installation health alarms of customer by customerId
     */
    async crmCustomersGetInstallationsHealthAlarmsByCustomerIdRaw(requestParameters: CrmCustomersGetInstallationsHealthAlarmsByCustomerIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthStatusAlarmsModel>> {
        if (requestParameters.customerId === null || requestParameters.customerId === undefined) {
            throw new runtime.RequiredError('customerId','Required parameter requestParameters.customerId was null or undefined when calling crmCustomersGetInstallationsHealthAlarmsByCustomerId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/installations/health/alarms/{customerId}`.replace(`{${"customerId"}}`, encodeURIComponent(String(requestParameters.customerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get the installation health alarms of customer by customerId
     */
    async crmCustomersGetInstallationsHealthAlarmsByCustomerId(requestParameters: CrmCustomersGetInstallationsHealthAlarmsByCustomerIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthStatusAlarmsModel> {
        const response = await this.crmCustomersGetInstallationsHealthAlarmsByCustomerIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get installation KPI`s of your customers
     */
    async crmCustomersGetInstallationsStatsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InstallationStatsModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/stats/installations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get installation KPI`s of your customers
     */
    async crmCustomersGetInstallationsStats(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InstallationStatsModel> {
        const response = await this.crmCustomersGetInstallationsStatsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get history of sent user invitations
     */
    async crmCustomersGetSendAccountUserInvitationsRaw(requestParameters: CrmCustomersGetSendAccountUserInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SentInvitationResponseModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersGetSendAccountUserInvitations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/users/{id}/sendinvitation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get history of sent user invitations
     */
    async crmCustomersGetSendAccountUserInvitations(requestParameters: CrmCustomersGetSendAccountUserInvitationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SentInvitationResponseModel>> {
        const response = await this.crmCustomersGetSendAccountUserInvitationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get KPI`s of your customers
     */
    async crmCustomersGetStatsRaw(requestParameters: CrmCustomersGetStatsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStats>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get KPI`s of your customers
     */
    async crmCustomersGetStats(requestParameters: CrmCustomersGetStatsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStats> {
        const response = await this.crmCustomersGetStatsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get customer stats data with the list of children records
     */
    async crmCustomersGetStatsCustomersRaw(requestParameters: CrmCustomersGetStatsCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerStatsChildren>> {
        const queryParameters: any = {};

        if (requestParameters.filters !== undefined) {
            queryParameters['filters'] = requestParameters.filters;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/stats/children`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Get customer stats data with the list of children records
     */
    async crmCustomersGetStatsCustomers(requestParameters: CrmCustomersGetStatsCustomersRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerStatsChildren> {
        const response = await this.crmCustomersGetStatsCustomersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Performs reactivation action on a customer, which unmarks it from deletion after set interval.
     */
    async crmCustomersReactivateCustomerRaw(requestParameters: CrmCustomersReactivateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserReactivationModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersReactivateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/reactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Performs reactivation action on a customer, which unmarks it from deletion after set interval.
     */
    async crmCustomersReactivateCustomer(requestParameters: CrmCustomersReactivateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserReactivationModel> {
        const response = await this.crmCustomersReactivateCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove assigned to customer feature group
     */
    async crmCustomersRemoveAssignedToCustomerFeatureGroupRaw(requestParameters: CrmCustomersRemoveAssignedToCustomerFeatureGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersRemoveAssignedToCustomerFeatureGroup.');
        }

        if (requestParameters.customerRemoveFeatureGroupModel === null || requestParameters.customerRemoveFeatureGroupModel === undefined) {
            throw new runtime.RequiredError('customerRemoveFeatureGroupModel','Required parameter requestParameters.customerRemoveFeatureGroupModel was null or undefined when calling crmCustomersRemoveAssignedToCustomerFeatureGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/feature-groups-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerRemoveFeatureGroupModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove assigned to customer feature group
     */
    async crmCustomersRemoveAssignedToCustomerFeatureGroup(requestParameters: CrmCustomersRemoveAssignedToCustomerFeatureGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersRemoveAssignedToCustomerFeatureGroupRaw(requestParameters, initOverrides);
    }

    /**
     * Send user invitation
     */
    async crmCustomersSendAccountUserInvitationRaw(requestParameters: CrmCustomersSendAccountUserInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersSendAccountUserInvitation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/users/{id}/sendinvitation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Send user invitation
     */
    async crmCustomersSendAccountUserInvitation(requestParameters: CrmCustomersSendAccountUserInvitationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersSendAccountUserInvitationRaw(requestParameters, initOverrides);
    }

    /**
     * Update a customer subscription package
     */
    async crmCustomersUpdateAccountSubscriptionRaw(requestParameters: CrmCustomersUpdateAccountSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersUpdateAccountSubscription.');
        }

        if (requestParameters.customerChangeSubscriptionsModel === null || requestParameters.customerChangeSubscriptionsModel === undefined) {
            throw new runtime.RequiredError('customerChangeSubscriptionsModel','Required parameter requestParameters.customerChangeSubscriptionsModel was null or undefined when calling crmCustomersUpdateAccountSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/subscription`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerChangeSubscriptionsModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a customer subscription package
     */
    async crmCustomersUpdateAccountSubscription(requestParameters: CrmCustomersUpdateAccountSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerResponseModel> {
        const response = await this.crmCustomersUpdateAccountSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a customer\'s tenant
     */
    async crmCustomersUpdateAccountUserRaw(requestParameters: CrmCustomersUpdateAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersUpdateAccountUser.');
        }

        if (requestParameters.customerChangeTenantModel === null || requestParameters.customerChangeTenantModel === undefined) {
            throw new runtime.RequiredError('customerChangeTenantModel','Required parameter requestParameters.customerChangeTenantModel was null or undefined when calling crmCustomersUpdateAccountUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/tenant`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerChangeTenantModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a customer\'s tenant
     */
    async crmCustomersUpdateAccountUser(requestParameters: CrmCustomersUpdateAccountUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerResponseModel> {
        const response = await this.crmCustomersUpdateAccountUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a customer
     */
    async crmCustomersUpdateCustomerRaw(requestParameters: CrmCustomersUpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CustomerResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersUpdateCustomer.');
        }

        if (requestParameters.customerUpdateModel === null || requestParameters.customerUpdateModel === undefined) {
            throw new runtime.RequiredError('customerUpdateModel','Required parameter requestParameters.customerUpdateModel was null or undefined when calling crmCustomersUpdateCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerUpdateModel,
        }, initOverrides);

        return new runtime.JSONApiResponse(response);
    }

    /**
     * Update a customer
     */
    async crmCustomersUpdateCustomer(requestParameters: CrmCustomersUpdateCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CustomerResponseModel> {
        const response = await this.crmCustomersUpdateCustomerRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update customer user
     */
    async crmCustomersUpdateUserRaw(requestParameters: CrmCustomersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersUpdateUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling crmCustomersUpdateUser.');
        }

        if (requestParameters.customerUserUpdateModel === null || requestParameters.customerUserUpdateModel === undefined) {
            throw new runtime.RequiredError('customerUserUpdateModel','Required parameter requestParameters.customerUserUpdateModel was null or undefined when calling crmCustomersUpdateUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/users/{userId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.customerUserUpdateModel,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update customer user
     */
    async crmCustomersUpdateUser(requestParameters: CrmCustomersUpdateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersUpdateUserRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint is used to upload a csv file which will be later used to create customers.
     */
    async crmCustomersUploadCsvCustomersRaw(requestParameters: CrmCustomersUploadCsvCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.csvData === null || requestParameters.csvData === undefined) {
            throw new runtime.RequiredError('csvData','Required parameter requestParameters.csvData was null or undefined when calling crmCustomersUploadCsvCustomers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.csvData !== undefined) {
            formParams.append('csvData', requestParameters.csvData as any);
        }

        const response = await this.request({
            path: `/api/v1/customers/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to upload a csv file which will be later used to create customers.
     */
    async crmCustomersUploadCsvCustomers(requestParameters: CrmCustomersUploadCsvCustomersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersUploadCsvCustomersRaw(requestParameters, initOverrides);
    }

    /**
     * This endpoint is used to upload a csv file on behalf of a customer which will be later used to create customers.
     */
    async crmCustomersUploadCsvForCustomerRaw(requestParameters: CrmCustomersUploadCsvForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling crmCustomersUploadCsvForCustomer.');
        }

        if (requestParameters.csvData === null || requestParameters.csvData === undefined) {
            throw new runtime.RequiredError('csvData','Required parameter requestParameters.csvData was null or undefined when calling crmCustomersUploadCsvForCustomer.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("OAuth2", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.csvData !== undefined) {
            formParams.append('csvData', requestParameters.csvData as any);
        }

        const response = await this.request({
            path: `/api/v1/customers/{id}/upload`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to upload a csv file on behalf of a customer which will be later used to create customers.
     */
    async crmCustomersUploadCsvForCustomer(requestParameters: CrmCustomersUploadCsvForCustomerRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.crmCustomersUploadCsvForCustomerRaw(requestParameters, initOverrides);
    }

}
